.sideImage {
  background-image: url("../../assets/sideimage.jpg");
  background-size: cover;
  position: relative;
  height: 100vh;
  display: block;
}

.serviceCard {
  width: 100%;
  height: 6em;
  border-radius: 1em;
  background-color: #bcd4d4;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .sideImage {
    display: none;
  }
}

div:hover.serviceCard {
  background-color: #9da7a7;
}
div.serviceCard {
  transition: background-color 1s;
  font-weight: 300;
  font-size: 16pt;
  box-shadow: 3px 4px 13px -5px rgba(0, 0, 0, 0.92);
  -webkit-box-shadow: 3px 4px 13px -5px rgba(0, 0, 0, 0.92);
  -moz-box-shadow: 3px 4px 13px -5px rgba(0, 0, 0, 0.92);
}
.firstCard {
  background-color: antiquewhite;
}

.statement {
  font-size: 2.5em;
  margin-bottom: 2em;
  font-weight: bold;
}

.container {
  width: 90%;
  display: flex;
  justify-content: center;
}
.secondCard {
  background-color: aliceblue;
}
.rightSide {
  margin-top: 2em;
  width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .signupCard {
    width: 100%;
  }
}

.signupCard {
  background-color: #ffffff;
  width: 400px;
  height: 25em;
  padding: 2em;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  box-sizing: border-box;
}

.cardContent {
  padding: 2em;
}

.socialButton {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.centerSide {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: auto;
}
