.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3em;

}

.title {
    color: #000C26;
    font-weight: 700;
font-size: 28px;
    margin: 1em 0;
    text-align: center;

}
.head {
    /* background: linear-gradient(90deg, #FBED96 0%, #ABECD6 100%); */
    background-size: 100%;
    height: 207px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
}

.circleClose {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 0.4px solid #C4C6CC; */
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  box-sizing: border-box;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    background: #FFFFFF;
    margin: 0.5em;
    position: absolute;
    top: 0;
    right: 5px;
}

.progressBar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.progress{
    width: 60px;
    height: 6px;
    background: #3DA31F;
    border-radius: 100px;
}
.subTitle {
    color: #3B404A;
    font-weight: 450;
font-size: 24px;
text-align: center;
}

.iconCircle {
    background: #FDEDED;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

}

.footer {
    color: var(--javolin-text-lt-gray);
    font-size: 17px;
    margin: 1em 0 0;
    font-weight: 600;
    cursor: pointer;
}

.pinInput {
    display: grid;
    grid-template-columns: repeat(6 1fr);
    column-gap: 23px;
    margin: 2em 0;
}
.confettiSec{
    border-radius: 100%;
    background: #ECF7E8;
    width: 200px;
    height: 200px;
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actionBtn{
    color: #0032A0;
    border: 1px solid #0032A0;
border-radius: 100px;
padding: 0.2em 1em;
margin-bottom: 1.5em;
cursor: pointer;
}
.star_bg {
    background: url('../../assets/stars.svg') top no-repeat;
}

.popupModals {
    top: 250px;
}

@media(max-width: 769px) {
    .popupModals {
        top: 100px;
    }

}
