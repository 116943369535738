.jp_card {
    border-radius: 10px;
    width: 100%;
    min-width: 250px;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 110px;
    /*box-shadow: 1px 2px 20px rgba(0, 50, 160, 0.4);*/
    cursor: pointer;
}

.textArea {
    display: flex;
    flex-direction: column;
}


.subTitle {
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
}


.jp_title {
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 10px;
    text-align: left;
    
}

.action {
    font-weight: bold;
    font-size: 10px;
    text-align: left;
}


