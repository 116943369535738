.optionBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.4em;
    padding: 1em;
    border-radius: 0.5em;
    border: 0.5px solid rgba(40, 80, 89, 0.1);
    background: #ffffff;
}

.leftSide {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.optionIcon {
    display: flex;
    justify-content: flex-start;
}

.optionBox:hover {
    background-color: "red";
    border: 1px solid "#0E60A2";
}

.optionLabel {
    margin-left: 2em;
    font-weight: bold;
}
