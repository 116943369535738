.pc_card{
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 background: #ffffff;
border: 0.01px solid #545FC2;
box-sizing: border-box;
border-radius: 0.5em;
width: 100%;
height: 158px;
box-shadow: none;
transition: all 0.6s;
}


.pc_card:hover{
    transform: scale(1.1);
    box-shadow: 3px 3px 20px rgba(31, 114, 192, 0.43);

}

.pc_card:active{
    background: rgba(36, 94, 162, 0.12);
    transform: scale(1.1);
}

.pc_card:focus{
    background: rgba(36, 94, 162, 0.12);
    transform: scale(1.1);
}
/* .pc_label{

}*/

.outterLabel{
    text-align: center;
    margin-top: 1em;
}

.pc_icon{

width: 50%;
} 