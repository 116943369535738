


.title{
    font-weight: 700;
font-size: 24px;
line-height: 31px;
color: #00050E;
}

.titleRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header{
    box-shadow: 0px 10px 20px rgba(163, 165, 168, 0.1);
    border-radius: 16px 16px 0 0 ;
    height: 115px;
    background: url(../../assets/headerpopup.png), #000638;
}


.javTag{
    padding: "10px";
    cursor:"pointer";

}

.declineBtn{
    color: "FF0000"
}

.approveBtn{
    color: '#008000' 
}
.secTitle{
    padding: 15px;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
    }

.permTile{
border: 1px solid #3DA31F;
border-radius: 4px;
 margin-top: "5px";
  padding: "10px"
}

.editButton{

}

.actionBtn{
    color: '#FFFFFF';
    padding: "5px 10px";
    border-radius: "20px";
    font-size: "16px";
}
.delete{

}