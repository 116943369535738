.javSider{
    position:'fixed';
    /* height: '900px'; */
    background-color: #ffffff;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.contentArea{
    display: flex;
    flex-direction: row;
}

.questionArea{
    padding: 3em;
    margin: auto;
}