
.pageContainer{
    display: flex;
    flex-direction: row;

}

.sider{
    flex: 0 0 400px;
    max-width: 400px;
min-width: 400px;
/* position: fixed; */

}
.titleCard {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: #ffffff;

}

.card {
    max-width: 1280px;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 16px;

}

.kyccardTitle {
    padding: 2em;
    /* background: url("../../assets/formheader.png") no-repeat; */
    background-size: 100%;
    color: #ffffff;
    background: #000638;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}
.mainContainArea{
    width: 100%;
    /* margin-left: 420px; */

}

.formArea{
    padding: 1em;
}

.steps{
position: -webkit-sticky; /* Safari */
position: sticky;
top: 0;
}

.buttonContainter {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.tnxButton2{
    max-width: 400px;
    width: 100%;
}

.listCard{
background: #E4EBFA;
border: 0.6px solid #0032A0;
border-radius: 12px;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
height: 96px;
margin-bottom: 1em;
cursor: pointer;
}

.cardTitle{
font-weight: 500;
font-size: 17px;
line-height: 21px;
color: #000C26;
}

.cardTitle, .action{
    padding: 1em;
}

.action{
    color: #ffffff;
}