.logoWrapper {
  text-align: center;
  padding: 1em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.logo {
  width: 8em;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.large-dashed-border {
  border: 8px solid transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

/*"Sofia Pro",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif */
