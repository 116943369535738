.card {
    /* background-color: #D4DFF7; */
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    height: fit-content;
    width: 100%;
    margin: 1em 0;
    cursor: pointer;
}
.card:hover {
    background: rgb(250 251 255);

}

.arrow {
    flex-basis: 10%
}
.iconBg {
    border-radius: 100%;
    padding: 7px;
}

.labelDiv {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;

    color: #000C26;
}

.subText {
    font-size: 13px;
    line-height: 16px;
    color: #5D6066;
}

.textArea {
    flex-basis: 70%
}
